/* eslint-disable */
import RichTextInput from 'ra-input-rich-text'
import * as React from 'react'
import {
  Edit,
  FormWithRedirect,
  TextInput,
  SelectInput,
  useTranslate,
  TextField,
  Datagrid,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  BooleanInput,
  FunctionField,
  FormDataConsumer
} from 'react-admin'
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core'

import HoursField from '../../Components/Fields/HoursField'
import { ChannelTextInput } from '../../Components/Inputs'

import CreateSOButton from '../ServiceOffering/Components/CreateSOButton'
import CreateSRButton from '../ServiceRealization/Components/CreateSRButton'

import { EditActions } from '../../Components'
import DateField from '../../Components/Datefield'

import * as PropTypes from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'

import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'

import getConfig from '../../Components/core_config'
import { ReferenceManyFieldAccordion } from '../../Components/Fields'
import dateAdmin from '../../Utils/dateAdmin'

const CustomListTableView = props => {
  const { record, title, buttons, children, ...rest } = props

  return (
    <Grid container item {...rest} >
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Typography variant="h5" component={'span'}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          {buttons}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item container style={{ height: '100%' }}/>
    </Grid>
  )
}

CustomListTableView.propTypes = {
  record: PropTypes.any,
  title: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.ReactElementLike),
  buttons: PropTypes.undefined | PropTypes.ReactNodeArray
}

const ServiceRealizationTable = props => (
  <ReferenceManyField
    reference="servicerealization"
    target="service_base"
    sort={{ field: 'start', order: 'ASC' }}
    filter={{ start: dateAdmin.parse(new Date(), 'yyyy-mm-dd') }}
    pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
    perPage={50}
    {...props}
  >
    <Datagrid rowClick='edit'>
      <DateField source="start" showTime/>
      <TextField source="status" />
    </Datagrid>
  </ReferenceManyField>
)

const ServiceRealizationListView = props => {
  return (
    <CustomListTableView
    id={'servicerealization-list-view-component'}
    title={'Service realizations ahead'}
    buttons={<CreateSRButton record={props.record}/>}
    sm={12}
    lg={4}
    {...props}>
      <ServiceRealizationTable {...props}/>
    </CustomListTableView>
  )
}

ServiceRealizationListView.propTypes = {
  record: PropTypes.object
}



const ServiceOfferingTable = props => (
  <ReferenceManyField
    reference="serviceoffering"
    target="service_base"
    sort={{ field: 'realization_start_from', order: 'ASC' }}
    filter={{ realization_start_to: dateAdmin.parse(new Date(), 'yyyy-mm-dd') }}
    pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
    perPage={50}
    {...props}
  >
    <Datagrid rowClick='edit'>
      <DateField source="realization_start_from" showTime/>
      <DateField source="realization_start_to" showTime/>
      <HoursField source="min_lead_time" sortable={false} />
      <TextField source="status" />
      <ReferenceField source="service_offering_config" reference="serviceofferingconfig">
        <TextField source="ident"/>
      </ReferenceField>
      <ReferenceField source="service_offering_template" reference="serviceofferingtemplate">
        <TextField source="ident"/>
      </ReferenceField>
    </Datagrid>
  </ReferenceManyField>
)

const ServiceOfferingListView = props => {
  return (
    <CustomListTableView
      id={'serviceoffering-list-view-component'}
      title={'Service offerings ahead'}
      buttons={<CreateSOButton record={props.record}/>}
      sm={12}
      lg={8}
      {...props}
    >
      <ServiceOfferingTable {...props}/>
    </CustomListTableView>
  )
}

ServiceOfferingListView.propTypes = {
  record: PropTypes.object
}


const onDemandChoice = 'datepicker_customtime'

const ServiceBaseEditForm = (props) => {
  useDefineAppLocation('catalog.servicebase.edit')
  const translate = useTranslate()
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'

  return (
    <FormWithRedirect
    mutators = {{
      setRealizationStartForOnDemand: (args, state, utils) => {
        utils.changeValue(state, 'realization_start_selector', () => onDemandChoice)
      }
    }}
    {...props}
    render={formProps => (
    <form>
      <SaveDeleteToolbar {...formProps} />
      <div style={{ padding: 20 }}>
        <Grid container spacing={4}>
          <Grid id="servicebase-intrasec-data" container item md={12} lg={6} spacing={2}>
            <Grid item xs={12}>
              <TextInput source="ident" fullWidth />
              <TextInput source="technical_name" fullWidth />
              <ChannelTextInput label="Time field subtitle" source="time_subtitle" editable {...props} />
              <TextInput source="suppliers_name" label={translate('resources.servicebase.fields.suppliers_name')} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <SelectInput
              source="realization_strategy"
              choices={config.realization_strategy}
              onChange={event => event.target.value === 'on_demand' && formProps.form.mutators.setRealizationStartForOnDemand()}
              fullWidth />
            </Grid>
            <Grid item xs={4}>
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                        <SelectInput
                            source="realization_start_selector"
                            choices={config.realization_start_selector}
                            disabled={formData.realization_strategy === 'on_demand'}
                            {...rest}
                            required
                            fullWidth
                        />
                )}
              </FormDataConsumer>
            </Grid>
            <Grid item xs={4}>
              <BooleanInput source="enabled" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <RichTextInput source="technical_note" />
            </Grid>
            <Grid item xs={12}>
              Last changed: <DateField source="service_modified_at" showTime />
            </Grid>
            <Grid item container style={{ height: '100%' }}/>
          </Grid>
          <Grid id="servicebase-connected-soc-sot" container item md={12} lg={6} spacing={2}>
            <Grid item xs={12}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary>
                  <Typography variant="h5">{"Today's price"}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        Price:
                      </TableCell>
                      <TableCell>
                        {props.record.price
                          ? <> {props.record.price} {props.record.currency}</>
                          : <> &ndash;</>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Price quantifier:
                      </TableCell>
                      <TableCell>
                        {props.record.price_quantifier
                          ? <> {props.record.price_quantifier}</>
                          : <> &ndash;</>
                        }
                      </TableCell>
                    </TableRow>
                    {props.record.original_price
                      ? <TableRow>
                          <TableCell>
                            Original price:
                          </TableCell>
                          <TableCell>
                            {props.record.original_price} {props.record.currency}
                          </TableCell>
                        </TableRow>
                      : <></>
                    }
                    {props.record.discount_text
                      ? <TableRow>
                          <TableCell>
                            Discount text:
                          </TableCell>
                          <TableCell>
                            {props.record.discount_text}
                          </TableCell>
                        </TableRow>
                      : <></>
                    }
                  </TableBody>
                </Table>

                </AccordionDetails>
              </Accordion>
              <ReferenceManyFieldAccordion
                title={translate('resources.serviceofferingconfig.title')}
                reference="serviceofferingconfig"
                target="service_base"
                defaultExpanded
              >
                <TextField source="ident"/>
                <HoursField source="min_lead_time"/>
                <FunctionField label="Main price" render={(record) => <>{record.main_price} {record.main_price_currency}</>} />
                <FunctionField label="Original main price" render={(record) => <>{record.original_main_price ? record.original_main_price + ' ' + record.main_price_currency : ''}</>} />
              </ReferenceManyFieldAccordion>
              <ReferenceManyFieldAccordion
                title={translate('resources.serviceofferingtemplate.title')}
                reference="serviceofferingtemplate"
                target="service_base"
                defaultExpanded
              >
                <TextField source="ident"/>
                <FunctionField label="Order form workflow" render={(record) => <>{config.order_form_template.find(o => o.id === record.content.order_form_template).name}</>} />
              </ReferenceManyFieldAccordion>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={2}>
            <ServiceOfferingListView target="service_base" {...props}/>
            <ServiceRealizationListView target="service_base" {...props}/>
          </Grid>
        </Grid>
      </div>
      <SaveDeleteToolbar {...formProps} />
    </form>
    )}
    />
  )
}

const ServiceEditTitle = (props) => {
  const translate = useTranslate()
  let title
  title = `${translate('resources.servicebase.title')}`
  title += ` ${props.id}: `
  title += props.record.ident

  return title
}

const ServiceBaseEdit = ({ permissions, ...props }) => {
  return (
    <Edit title={<ServiceEditTitle {...props}/>} actions={<EditActions tour="servicebase-edit"/>} {...props}>
      <ServiceBaseEditForm />
    </Edit>
  )
}

ServiceBaseEdit.propTypes = {
  permissions: PropTypes.arrayOf(String),
  id: PropTypes.string
}

ServiceBaseEditForm.propTypes = {
  record: PropTypes.object
}

export default ServiceBaseEdit
