import drfDataProvider from 'ra-data-django-rest-framework'
import { fetchUtils } from 'react-admin'
// import { addTreeMethodsBasedOnParentAndPosition } from '@react-admin/ra-tree'

let BASE_URL = 'http://localhost:8000'
let API_URL = BASE_URL + '/admin'
let MEDIA_URL = BASE_URL + '/media'

if (process.env.NODE_ENV === 'production') {
  if (window.CONFIG) {
    const keys = Object.keys(window.CONFIG)
    if (keys.indexOf('BASE_URL') >= 0) BASE_URL = window.CONFIG.BASE_URL
    if (keys.indexOf('API_URL') >= 0) API_URL = window.CONFIG.API_URL
    else API_URL = BASE_URL + '/admin'
    if (keys.indexOf('MEDIA_URL') >= 0) MEDIA_URL = window.CONFIG.MEDIA_URL
    else MEDIA_URL = BASE_URL + '/media'
  }
}

const httpClient = (url, options = {}, removeSlash = true) => {
  if (url.indexOf('/?') > 0) url = url.replace('/?', '?')
  else if (removeSlash && url.lastIndexOf('/') === url.length - 1) url = url.substr(0, url.length - 1)
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const token = localStorage.getItem('token')
  options.headers.set('Authorization', `Token ${token}`)
  return fetchUtils.fetchJson(url, options)
}

const dataProvider = drfDataProvider(API_URL, httpClient)
// const dataProviderWithTree = addTreeMethodsBasedOnParentAndPosition(dataProvider, 'parent', 'sort_order', false)

export const blobToData = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

const fieldToBase64 = (fieldName, params) => {
  return new Promise((resolve) => {
    fetch(params.data[fieldName].src).then(r => {
      r.blob().then((blob) => {
        const filename = params.data[fieldName].title
        blobToData(blob).then((filedata) => {
          const base64file = `filename:${filename};${filedata}`
          resolve(base64file)
        })
      })
    })
  })
}

const customDataProvider = {
  ...dataProvider,
  getCustom: async (resource, params) => {
    const { json } = await httpClient(`${API_URL}/${resource}/${params.data}`, {
      method: 'GET'
    })
    return {
      data: { ...json }
    }
  },
  getTree: async (resource) => {
    if (resource === 'menutree') {
      return dataProvider.getList('menuitem', { filter: {}, pagination: { page: 1, perPage: 100000 }, sort: { field: 'sort_order', order: 'ASC' } })
    }
  },
  getRootNodes: async (resource) => {
    if (resource === 'menutree') {
      return dataProvider.getList('menuitem', { filter: { no_parent: true }, pagination: { page: 1, perPage: 100000 }, sort: { field: 'sort_order', order: 'ASC' } })
    }
  },
  getParentNode: async (resource, params) => {
    if (resource === 'menutree') {
      const result = dataProvider.getList('menuitem', { filter: { children: params.childId }, pagination: { page: 1, perPage: 100000 }, sort: { field: 'sort_order', order: 'ASC' } }).then((result) => {
        result.data = result.data[0]
        return result
      })
      return result
    }
  },
  getChildNodes: async (resource, params) => {
    if (resource === 'menutree') {
      return dataProvider.getList('menuitem', { filter: { parent: params.parentId }, pagination: { page: 1, perPage: 100000 }, sort: { field: 'sort_order', order: 'ASC' } })
    }
  },
  addRootNode: async (resource, params) => {
    if (resource === 'menutree') {
      params.data.parent = null
      params.data.children = []
      return dataProvider.create('menuitem', params)
    }
  },
  addChildNode: async (resource, params) => {
    if (resource === 'menutree') {
      params.data.parent = params.parentId
      params.data.children = []
      return dataProvider.create('menuitem', params)
    }
  },
  moveAsNthChildOf: async (resource, params) => {
    if (resource === 'menutree') {
      const dataToMove = params.source
      dataToMove.parent = params.destination.id
      return dataProvider.update('menuitem', { id: params.source.id, previousData: params.source, data: dataToMove })
    }
  },
  deleteBranch: async (resource, params) => {
    if (resource === 'menutree') {
      return dataProvider.delete('menuitem', params)
    }
  },
  getList: async (resource, params) => {
    if (resource === 'offeringcalendar') {
      return dataProvider.getList('serviceoffering', params)
    } else if (resource === 'realizationcalendar') {
      return dataProvider.getList('servicerealization', params)
    } else if (resource === 'menutree') {
      return dataProvider.getList('menuitem', params)
    } else if (resource === 'basket') {
      return dataProvider.getList('order', params)
    }
    return dataProvider.getList(resource, params)
  },
  getOne: async (resource, params) => {
    if (resource === 'offeringcalendar') {
      return dataProvider.getOne('serviceoffering', params)
    } else if (resource === 'realizationcalendar') {
      return dataProvider.getOne('servicerealization', params)
    } else if (resource === 'menutree') {
      return dataProvider.getOne('menuitem', params)
    } else if (resource === 'basket') {
      return dataProvider.getOne('order', params)
    }
    return dataProvider.getOne(resource, params)
  },

  delete: async (resource, params) => {
    if (resource === 'offeringcalendar') {
      return dataProvider.delete('serviceoffering', params)
    } else if (resource === 'realizationcalendar') {
      return dataProvider.delete('servicerealization', params)
    } else if (resource === 'menutree') {
      return dataProvider.delete('menuitem', params)
    } else if (resource === 'basket') {
      return dataProvider.delete('order', params)
    }
    return dataProvider.delete(resource, params)
  },
  update: async (resource, params) => {
    if (resource === 'photo') {
      params.data.image = await fieldToBase64('image', params)
    }
    if (resource === 'offeringcalendar') {
      return dataProvider.update('serviceoffering', params)
    } else if (resource === 'realizationcalendar') {
      return dataProvider.update('servicerealization', params)
    } else if (resource === 'menutree') {
      return dataProvider.update('menuitem', params)
    } else if (resource === 'basket') {
      return dataProvider.update('order', params)
    }
    const { json } = await httpClient(`${API_URL}/${resource}/${params.id}/`, {
      method: 'PATCH',
      body: JSON.stringify(params.data)
    })
    return { data: json }
  },

  create: async (resource, params) => {
    if (resource === 'photo') {
      params.data.image = await fieldToBase64('image', params)
    } else if (resource === 'files/upload') {
      params.data.file = {
        data: await fieldToBase64('file', params),
        file_path: params.data.file.path
      }
    } else if (resource === 'offeringcalendar') {
      return dataProvider.create('serviceoffering', params)
    } else if (resource === 'realizationcalendar') {
      return dataProvider.create('servicerealization', params)
    } else if (resource === 'menutree') {
      return dataProvider.create('menuitem', params)
    } else if (resource === 'basket') {
      return dataProvider.create('order', params)
    }
    const { json } = await httpClient(API_URL + '/' + resource + '/', {
      method: 'POST',
      body: JSON.stringify(params.data)
    }, true)
    return {
      data: { ...json }
    }
  }
}

export default customDataProvider

export { API_URL, BASE_URL, MEDIA_URL, httpClient }
